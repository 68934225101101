<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Recordings</Text>
      <Btn
        @click="to('/dashboard/recordings/upload')"
        v-if="$store.getters.isAdmin"
        >Upload</Btn
      >
    </List>

    <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <div
          :class="{ active: videos.section == 'all' }"
          @click="setVideoSection('all')"
        >
          All
        </div>
        <div
          :class="{ active: videos.section == 'programming' }"
          @click="setVideoSection('programming')"
        >
          Programming
        </div>
        <div
          :class="{ active: videos.section == 'automation' }"
          @click="setVideoSection('automation')"
        >
          Automation
        </div>
        <div
          :class="{ active: videos.section == 'SDLC' }"
          @click="setVideoSection('SDLC')"
        >
          SDLC
        </div>

        <div
          :class="{ active: videos.section == 'draft' }"
          @click="setVideoSection('draft')"
          v-if="$store.getters.isAdmin"
        >
          Draft
        </div>
      </div>
      <div el="input-list">
        <List el="column xsm">
          <!-- <div class="dropdown" v-if="$store.getters.isAdmin">
            <span
              class="material-icons"
              @click="
                videos.filters.showDropdown = !videos.filters.showDropdown
              "
              >filter_list</span
            >

            <div class="menu" v-if="videos.filters.showDropdown">
              <div>
                <Text>Tags</Text>
                <div class="tags">
                  <Text
                    v-for="item in videos.filters.tagList"
                    :key="item"
                    @click="toggleTag(item)"
                    :class="{ active: videos.filters.tags.includes(item) }"
                    >{{ item }}</Text
                  >
                </div>
              </div>
            </div>
          </div> -->
          <label el="input">
            <input
              type="text"
              placeholder="Search..."
              v-model="videos.searchInput"
              el
            />
          </label>
        </List>
      </div>
    </List>

    <table el="table">
      <thead>
        <tr>
          <th width="165px">Lecture Date</th>
          <th>Title</th>
        </tr>
      </thead>
      <tbody v-if="filteredRecordings != null">
        <tr
          v-for="item in filteredRecordings"
          :key="item"
          @click="to('/dashboard/recordings/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <td>{{ formatDate(item.date) }}</td>
          <td>{{ item.label }}</td>
        </tr>
        <!-- <tr>
          <td>Lowis</td>
          <td>Lane</td>
        </tr> -->
      </tbody>
    </table>
  </List>
</template>
<script>
import router from "@/router";
let filter = require("lodash/filter");
let lowerCase = require("lodash/lowerCase");
let includes = require("lodash/includes");

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      groups: this.$store.state.main.groups,
      videos: {
        searchInput: "",
        section: "programming",
        filters: {
          showDropdown: false,
          tag: "programming",
          // tagsContain:['SET 3'],
          tags: ["SET 4"],
          tagList: ["SET 3", "SET 4"],
          status: "publish",
        },
      },
    };
  },
  watch: {
    "videos.section": function (val) {
      this.videos.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
    "groups.active": function (val) {
      this.videos.filters.tags = [val];
    },
  },
  methods: {
    toggleTag(item) {
      let tags = this.videos.filters.tags;
      if (tags.includes(item)) {
        let i = tags.indexOf(item);
        if (i > -1) {
          tags.splice(i, 1);
        }
      } else {
        tags.push(item);
      }
    },
    formatDate(dateString) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(`${dateString} 00:00:00`);
      return `${dateString}, ${days[d.getDay()]}`;
    },
    setVideoSection(name) {
      let x = this;
      localStorage.setItem("recordingsSection", name);
      x.videos.section = name;
    },
    loadPrevSection(){
      let x = this;
      let section = localStorage.getItem("recordingsSection");
      if(section != null){
        x.videos.section = section;
      }
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    filteredRecordings() {
      // var input = this.modules.searchInput;
      let x = this;
      let checker = (arr, target) => target.every((v) => arr.includes(v));
      
      if(x.videos.section === "all"){
        return filter(this.$store.state.recordings.list, function (item) {
          return (
            checker(item.tags, x.videos.filters.tags) == true &&
              item.status == "publish" &&
              lowerCase(`${item.date} ${item.label}`).search(
                lowerCase(x.videos.searchInput)
              ) > -1
            );
        });
      }else{
          return filter(this.$store.state.recordings.list, function (item) {
          if (x.videos.section === "draft") {
            return (
              item.status == "draft" &&
              lowerCase(`${item.date} ${item.label}`).search(
                lowerCase(x.videos.searchInput)
              ) > -1
            );
          } else {
            return (
              checker(item.tags, x.videos.filters.tags) == true &&
              // includes(item.tags, x.videos.filters.tags[0]) == true &&
              includes(item.tags, x.videos.filters.tag) == true &&
              item.status == "publish" &&
              lowerCase(`${item.date} ${item.label}`).search(
                lowerCase(x.videos.searchInput)
              ) > -1
            );
          }

          // return (
          //   _.toLower(item.label).search(_.toLower(input)) >= 0 &&
          //   item.show == true
          // );
        });
      }
      
      
      
    },
    user() {
      return this.$store.state.main.user;
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    if (includes(this.user.groups, "SET 4")) {
      this.videos.filters.tags = ["SET 4"];
    }
    this.$store.commit("recordings/loadList");
    this.loadPrevSection();
    this.videos.filters.tags = [this.groups.active];
  },
};
</script>
<style lang="scss" scoped>

.dropdown {
  position: relative;
}
.dropdown .menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  top: 100%;
  z-index: 5;
  min-height: 100px;
  width: 250px;
  box-shadow: 0 3px 8px hsla(0, 0%, 0%, 0.05);
  border-radius: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdown .menu > *:not(.none) {
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  justify-content: start;
  color: var(--color-65);
}
.dropdown .menu > * .material-icons {
  font-size: 24px;
}
.dropdown .menu > *:not(.none):hover {
  background-color: rgba(0, 0, 0, 0.07);
  color: var(--color-85);
}

.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
</style>
